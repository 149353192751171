import { OrderStatus } from '../../../services/api/order/OrderService.types'
import DemandStatusBadge from '../demand/detail/DemandStatusBadge'
import React, { ReactNode } from 'react'

export type AppBarTitleProps = {
    title: string | ReactNode
    demandStatus?: OrderStatus
    rightTitle?: ReactNode
}

const AppBarTitle = ({ title, demandStatus, rightTitle }: AppBarTitleProps) => {
    if (typeof title === 'string') {
        return (
            <div className=' flex flex-row gap-2 items-center'>
                <h1 className='font-bold text-2xl leading-10 mr-2'>{title}</h1>
                {demandStatus && <DemandStatusBadge status={demandStatus} />}
                {rightTitle}
            </div>
        )
    }
    return <>{title}</>
}

export default AppBarTitle
