import { IconProps } from './Icon.types'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'

export const PersonIcon = ({ className }: IconProps) => {
    return (
        <svg
            className={buildClassesWithDefault('h-[48px] w-[48px]', className)}
            viewBox='0 0 48 48'
            fill='none'
            strokeWidth='1.5'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M24 24C26.2091 24 28 22.2091 28 20C28 17.7909 26.2091 16 24 16C21.7909 16 20 17.7909 20 20C20 22.2091 21.7909 24 24 24Z' />
            <path d='M16 30C16 28 19 26 24 26C29 26 32 28 32 30C32 31.1046 31.1046 32 30 32H18C16.8954 32 16 31.1046 16 30Z' />
        </svg>
    )
}
