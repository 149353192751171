import { LogoutIcon, UserIcon } from '@heroicons/react/outline'
import { PersonIcon } from '../../base/icon/Person'
import { useCurrentUser, useSignOut } from '../../../auth/auth'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import Dropdown from '../../base/dropdown/Dropdown'
import DropdownItem from '../../base/dropdown/DropdownItem'
import Link from 'next/link'
import React from 'react'

const AppBarUserMenu = () => {
    const { t } = useTranslation()
    const { user } = useCurrentUser()
    const { signOut } = useSignOut()
    const queryClient = useQueryClient()

    const handleLogout = () => {
        queryClient.cancelQueries()
        signOut()
    }

    return (
        <div className='flex items-center'>
            {/*            <Dropdown
                bordered
                activator={(props, ref) => (
                    <Button {...props} ref={ref} color='gray' variant='outlined' className='mx-1'>
                        {i18n.language.toUpperCase()}
                    </Button>
                )}
            >
                {['cs', 'en'].map((item, index) => (
                    <DropdownItem key={index} active={i18n.language === item} as={Link} locale={item} href='/dashboard'>
                        {item.toUpperCase()}
                    </DropdownItem>
                ))}
            </Dropdown>*/}
            <Dropdown
                placement='bottom-end'
                activator={(props, ref) => {
                    return (
                        <div {...props} ref={ref}>
                            <Link href='/app/profile'>
                                <PersonIcon fill='none' className='stroke-primary' />
                            </Link>
                        </div>
                    )
                }}
            >
                <div className='flex items-center'>
                    <div className='flex gap-1 pt-3 pb-2 pl-3.5'>
                        <div className='text-md text-body-regular font-normal text-black'>{user?.firstName}</div>
                        <div className='text-md text-body-regular font-normal text-black'>{user?.lastName}</div>
                    </div>
                </div>

                <DropdownItem as={Link} href='/app/profile' icon={<UserIcon />}>
                    {t('frontend.appbar.user.profile')}
                </DropdownItem>
                <DropdownItem onClick={handleLogout} icon={<LogoutIcon />}>
                    {t('frontend.appbar.user.logout')}
                </DropdownItem>
            </Dropdown>
        </div>
    )
}

export default AppBarUserMenu
